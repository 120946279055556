/* .time {
  width: 100px;
  height: 335px;
  z-index: 10000;
  margin-top: 10px;
  right: 0;

  //background-color: white;
  padding: 15px;
  //box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  border-radius: 13px;
  position: absolute;
  overflow: scroll;

  &__title {
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: bold;
  }

  &__time {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
   

  }


  &__minutee {
    
    padding: 5px;
    font-weight: bold;
    background-color: #49CD6E ;
    color: white;
    margin-left: 10px;
    float: right;
  }

  &__hourr{
    padding: 5px;
    font-weight: bold;
    background-color: #49CD6E ;
    color: white;
    float: left;
  
  
  }

  &__minute {
    font-size: 17px;
  
    margin-left: 20px;
  }

  &__hour{
    font-size: 17px;
  
    //margin-left: 10px;
  }


 


}
 */

.react-calendar {
  abbr {
    content: none !important;
    text-decoration: none !important;
  }

  max-width: 100%;
  background: white;
  border: 0px solid #a0a096 !important;
  font-family: Arial,
  Helvetica,
  sans-serif;
  line-height: 1.125em;
  width: 700px !important;
  &--doubleView {
    width: 700px;
    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;
      >* {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    &__label {
      &__labelText {
        line-height: 26px !important;
        font-family: inherit !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        color: inherit !important;
        display: inline-block !important;
        margin-left: 0.5ch !important;
        padding: 0 !important;
      }
    }

    button {
      min-width: 44px;
      background: none;

      &:disabled {
        background-color: rgb(240, 240, 240);
      }

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
      &__weekday {
        padding: 0.5em;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        margin: 20px 0 !important;
        letter-spacing: 0.25px !important;
        color: #2A2A30 !important;
        display: block !important;
      }
    }

    &__weekNumbers {
      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px !important;
        font-weight: normal !important;
        color: #737484 !important;
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    width: 100% !important;
    min-width: auto !important;
    border-radius: 10px !important;
    flex-basis: 10.2857143% !important;
    margin: 10px 2% !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    &:disabled {
      background-color: rgb(240, 240, 240);
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--now {
      &:enabled {
        &:hover,
        &:focus {
          //background: lighten(@bgcolor, 10%);
        }
      }
    }

    &--hasActive {
      &:enabled {
        &:hover,
        &:focus {
          //background: lighten(@bgcolor, 10%);
        }
      }
    }

    &--active {
      background: #49CD6E !important;
      color: white;
      &:enabled {
        &:hover,
        &:focus {
          //background: lighten(@bgcolor, 10%);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}







/*.react-calendar {
  width: 700px !important;
  min-height: 493px !important;
  max-width: 100%;
  background: white;
  border: 0px solid #a0a096 !important;
  //font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;

  width: 100% !important;
  min-width: auto !important;

  //border-radius: 10px !important;
  //width: 10.2857143% !important;
  //flex-basis: 10.2857143% !important;
  //margin: 10px 2% !important;
  ////font-size: 20px !important;
  //font-weight: 600 !important;
  //
  //max-width: 14.2857143% !important;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;

}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}*/