@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}
h2 {
  margin: 0;
}

.tab {
  table-layout: fixed;
  border: 0;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.holidays {
  grid-area: content;
  &__title {
    margin-left: 30px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
  }

  &__add {
    font-weight: bold;
    font-size: 16px;
    float: right;
    margin-top: -65px;
    margin-right: 20px;
  }

  &__title-block {
    margin-bottom: 20px;
    margin-right: 20px;
    display: flex;
    .title-holiday {
      margin-left: 30px;
      font-style: normal;
      width: 100%;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    .arrow {
      margin-right: 8px;
      margin-top: 8px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &__title-block.private {
    margin-top: 25px;
  }

  &__line {
    border-top: 1px solid $background-border-table;
    border-bottom: none;
    margin-left: -5px;
  }

  &__table {
    table-layout: inherit;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 40px;
    &:nth-last-child(-n+1) {
      margin-bottom: 0;
    }

    .thead {
      .arrow {
        width: 20px;
        height: 20px;
        cursor: pointer;
        float: right;
        margin-right: 30px;
      }

      .t {
        width: 300px;
      }

      .th {
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        border: solid $background-border-table;
        border-width: 1px 0;
        font-weight: 600;
        color: $btn-background;
      }
    }

    .active {
      display: none;
    }

    .tbody {
      .tr {
        background: $background-even;
        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n + 1) {
          border-bottom: 1pt solid $background-border-table;
        }

        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
          &:nth-last-child(-n + 1) {
            width: 20%;
          }
        }
        
        .button {
          margin-left: 15px;
        }
      }

      .tr:hover {
        background: rgba(219, 217, 217, 0.459);
      }
    }
  }
}
