@import "../../assets/sass/var";

 .fieldss {
    margin-bottom: 40px;
    position: relative;
    &_select {
        height: 64px;
        width: 100%;
        //outline: none;
        border: 2px solid #D6D8E7;
        letter-spacing: 0.75px;
        border-radius: 16px;
        background-color: $btn-text-color;
        font-size: $input-text-font-size;
        color: $btn-background;
        //margin-bottom: 0px;
        padding: 0px 24px;
        padding-bottom: 0px;
        
    }
    &_select:hover {
        border: 2px solid $btn-background;

    }

} 
/* * {
    margin: 0;
    padding: 0;
}
.container {
    width: 100%;
    height: 100vh;
}
.dropdown {
    position: relative;
}
.dropdown .dropdown-btn {
    //width: 400px;
    margin: 100px auto;
    height: 64px;
    width: 100%;
    //box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.6);
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $input-text-font-size;
    color: $btn-background;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    cursor: pointer;
}
.dropdown .dropdown-btn:hover {
   border: 2px solid black;
}
.dropdown .dropdown-content {
    left: 0;
    position: absolute;
    top: 70%;
    padding: 10px;
    background: white;
    border: 2px solid #D6D8E7;
    width: 100%;

}
.dropdown .dropdown-content .dropdown-items {
    padding: 10px;
    cursor: pointer;

}
.dropdown .dropdown-content .dropdown-items:hover {
    background-color: rgba(209, 209, 209, 0.671);
    
} */