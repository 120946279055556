@import "../../../assets/sass/var";

.events-window {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.199);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  &__alert {
    padding: 20px ;
    border-radius: 5px;
    background: $main-background;
    min-width: 512px;
    min-height: 360px;
    //min-height: 420px;
    transition: .5s all;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      max-width: 100%;
      margin: 0;
      //padding: 0.8em 1em 0;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
      margin-bottom: 16px;

      font-style: normal;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: #2A2A30;

      &__edd-button {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #49CD6E;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      }
      &__edd-button:hover {
        cursor: pointer;
      }

      &__time {

      }

      &__date {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.25px;
        color: #737484;
        margin-left: 260px;
      }
    }

    .events-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: start;
      overflow: hidden;
      //padding-bottom: 2%;
      position: absolute;
      width: 22%;
      min-height: 30%;

      .day-event {
        border-left: 2px solid black;
        width: 30%;
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        font-size: 12px;
        margin-bottom: 10px;
        margin-right: 10px;
        z-index: 10;

        .reason {
          font-weight: bold;
          display: block;
        }

        .start {
          display: block;
          font-weight: normal;
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 35px;
          height: 35px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;

          .photo {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
        }


      }
      .day-event:hover {
        cursor: pointer;
      }


      .grid {
        position: absolute;
        top: 0%;
        left: -3%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 103%;
        height: 100%;
        //height: 15px;
        .line-grid {
          height: 8.3%;
        }
      }

    }


    .edd-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 65%;
      left: 48.5%;
      z-index: 100;
      //width: 32px;
      width: 72px;
      height: 32px;
      border-radius: 5%;
      background: #49CD6E;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);

      &_title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
      }
    }
    .edd-button:hover {
      cursor: pointer;
    }

    .button {
      margin: 5px;
    }
  }
}





/*
@import "src/assets/sass/var";

.delete-window {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.199);


  &__alert {
    width: 512px;
    height: 220px;
    position: absolute;
    left: 38%;
    top: 38%;

    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    max-width: 100%;
    border: none;
    border-radius: 5px;
    background: $main-background;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
    padding: 20px;

    .title {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding: 0.8em 1em 0;
      color: inherit;
      font-size: 1.875em;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
    }

    .btn-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
*/
