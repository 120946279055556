@import "src/assets/sass/var";

.form-client-filter {
  display: block;
  width: 290px;
  grid-area: navbar;
  overflow: scroll;
  height: 100vh;
  padding-bottom: 100px;
  margin-top: 52px;
  .search {
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: -20px;
    width: 230px;
  }

  .filter-block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .filter-title {
      display: flex;
      align-items: center;
      margin: 10px 30px 3px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
    }
    .filter-title1 {
      display: flex;
      align-items: center;
      margin: 20px 30px 10px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
    }

    .filter-param {
      margin: 20px 30px -30px 30px;
    }

    .filter-param1 {
      margin: 10px 30px -40px 30px;
    }

    .filter-param1.param-branch {
      margin: 10px 30px -40px 30px;
    }

    .filter-param-none {
      display: none;
    }
    
    .filter-param:hover {
      cursor: pointer;
    }
  }
}