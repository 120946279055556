@import "src/assets/sass/var";

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .button-container {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 0 auto;
    .btn-pagination-disabled {
      background: $main-background;
      border: none;
      opacity: 0.5;
    }

    .btn-pagination {
      background: $main-background;
      border: none;
    }
    
    .btn-pagination:hover {
      cursor: pointer;
    }

    .page-number {
      font-weight: normal;
      font-style: normal;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #2A2A30;
    }
  }
}