@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;
}

.admin {
  min-width: 100%;
  min-height: 100vh;
  background: $main-background;
  display: grid;
  grid-template-areas:
   'navbar header'
    'navbar content';
  grid-template-rows: 84px 1fr;
  grid-template-columns: 290px 1fr;
  &__left {
    grid-area: navbar;
    border-radius: 0 40px 40px 0;
    background: $btn-text-color;
    width: 288px;
    height: 100vh;
    position: fixed;
    display: block;
    &-logo {
      width: 100%;
      .img {
        display: block;
        width: 120px;
        margin: 30px 0 50px 30px;
      }
    }

    &-nav {
      display: block;
      .list {
        list-style: none;
        padding: 0 30px;
        margin: 0;
        .item a {
          list-style: none;
          line-height: 1;
          padding: 0 0 20px 0;
          margin: 0;
          display: block;
          font-size: 16px;
          color: $paragraph-text-background;
          letter-spacing: 0.25px;
          text-decoration: none;
        }

        .item a:hover {
          text-decoration: underline;
        }

        .item a:active {
          font-weight: bold;
        }
      }
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    &_nav {
      display: block;
      clear: both;
      float: left;
      &_list {
        padding: 0;
        margin: 0;
        &_item {
          list-style: none;
          padding: 0;
          float: left;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.25px;
          &_link {
            text-decoration: none;
            color: $paragraph-text-background;
            display: block;
            padding: 25px 0px 0px 30px;
            .span:hover {
              border-bottom: 2px solid #49CD6E;
            }
          }
        }
      }
    }

    .profile {
      display: block;
      float: right;
      margin: 20px;
      height: 40px;
      .user {
        float: right;
        display: inline-block;
        width: 350px;
        height: 40px;
        .user-name {
          float: right;
          font-size: 16px;
          font-weight: 500;
          text-align: right;
          padding-right: 10px;
          cursor: pointer;
          line-height: 40px;
          background-size: 12px 12px;
          display: flex;
          .first-name {
            font-weight: 500;
            margin-right: 10px;
            font-size: 16px;
            text-align: right;
            cursor: pointer;
            line-height: 40px;
          }

          .last-name {
            font-size: 16px;
            font-weight: 500;
            text-align: right;
            cursor: pointer;
            line-height: 40px;
            padding-right: 10px;
          }

          .arrow {
            width: 15px;
            height: 15px;
            margin-top: 12px;
          }
        }

        .user-photo {
          float: right;
          overflow: hidden;
          margin-right: 10px;
          width: 40px;
          height: 40px;
          background-size: 40px 40px;
          box-sizing: border-box;
          border-radius: 100%;
          display: block;
          position: relative;
        }
      }

      .profile-menu {
        display: none;
        z-index: 100;
        position: absolute;
        right: 20px;
        top: 70px;
        padding: 12px 24px;
        background: $main-background;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        border-radius: 12px;
        list-style: none;
      }

      .profile-menu-open {
        z-index: 10000000000;
        position: absolute;
        right: 20px;
        top: 70px;
        padding: 12px 24px;
        background: $main-background;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        border-radius: 12px;
        list-style: none;
        .nav {
          list-style: none;
          .menu-list {
            list-style: none;
            padding: 0;
            .list-item {
              padding: 4px 0;
              margin: 0;
              font-size: 14px;
              .item-link {
                color: $paragraph-text-background;
                text-decoration: none;
              }

              .item-link:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 0;
  }

  .admin {
    min-width: 800px;
    min-height: 200vh;
    position: absolute;
  }
}
