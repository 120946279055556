@import "src/assets/sass/var";

.form-event-filter {
  display: block;
  width: 290px;
  grid-area: navbar;
  overflow: scroll;
  height: 100vh;
  padding-bottom: 100px;
  margin-top: 52px;
  .search {
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: -30px;
    width: 230px;
  }

  .color {
    width: 11px;
    height: 11px;
    border-radius: 12px;
    margin-top: 5px;
    margin-bottom: 39px;

  }

  .filter-block {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    .filter-title {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 20px 30px 23px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
      .filter-title-name {
        width: 74.5%;
      }

      cursor: pointer;
      .arrow-event-filter1 {
        //position: relative;
        margin-left: 140px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .arrow-event-filter {
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
    .filter-title2 {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 10px 30px 23px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
      .filter-title-name {
        width: 74.5%;
      }

      cursor: pointer;
      .arrow-event-filter1 {
        //position: relative;
        margin-left: 140px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .arrow-event-filter {
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }


    .filter-title1 {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 20px 30px 10px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;

    }

    .filter-param {
      margin: 0px 30px -10px 30px;
    }

    .filter-param.param-branch {
      margin: 10px 30px -40px 30px;
    }

    .filter-param:hover {
      cursor: pointer;
    }

    .filter-param-none {
      display: none;
    }
  }
  

  .filter-block-color {
    display: flex;
    flex-direction: row;
    align-content: stretch;

    .filter-title {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 20px 30px 23px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
      .filter-title-name {
        width: 74.5%;
      }

      cursor: pointer;
      .arrow-event-filter1 {
        margin-left: 140px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .arrow-event-filter {
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }

    .filter-title1 {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 20px 30px 10px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;

    }

    .filter-param {
      margin: 0px 30px -10px 30px;
    }

    .filter-param.param-branch {
      margin: 10px 30px -40px 30px;
    }

    .filter-param:hover {
      cursor: pointer;
    }

    .filter-param-none {
      display: none;
    }
  }

  .filter-title-color {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin: 20px 30px 23px 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.3px;
    .filter-title-name-color {
      width: 74.5%;
    }

    cursor: pointer;
    .arrow-event-filter1 {
      margin-left: 140px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .arrow-event-filter-color {
      align-items: flex-end;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}

