@import "../../../src/assets/sass/var.scss";

.template-order {
    padding-bottom: 40px;
    grid-area: content;
    &__title {
        margin-top: -40px;
        font-size: 18px;
        font-weight: bold;
        color: #2a2a30;
        line-height: 21px;
        letter-spacing: 0.5px;
    }

    &__table {
        margin-top: 20px;
        font-size: 18px;
        table-layout: fixed;
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        margin-bottom: 40px;
        display: table;
        box-sizing: border-box;
        text-indent: initial;
        border: 1px solid silver;
        border-spacing: 2px;
        .tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
            .tr {
                padding: 5px 5px;
                border: 1px solid silver;
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
                .th {
                    color: black;
                }

                .th-color {
                    font-weight: bold;
                    text-align: center;
                    font-size: 16px;
                    color: white;
                    //padding: 0px 0px -851px 0px;
                    border: 1px solid #2694da;
                    //padding: 0;
                    margin: 0;
                    background: #2694da;
                }

                .td {

                    font-weight: bold;
                    text-align: center;
                    font-size: 16px;
                    color: #2a2a30;
                    //padding: 0px 0px -851px 0px;
                    border: 1px solid silver;
                    //padding: 0;
                    margin: 0;
                    background: white;
                }

                .td-color {
                    font-size: 16px;
                    background-color: #2694da;
                    border: 1px solid #2694da;
                    padding: 0;
                    margin: 0;
                    font-weight: bold;
                    color: white;
                    text-align: center;
                }

                .td-middle {
                    width: 35px;
                    background: white;
                }
            }
        }
    }

    .wrapper {
        .page {
            &__header {
                margin-left: 30px;
                margin-bottom: -25px;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 66px;
                letter-spacing: 1px;
                color: $btn-background;
            }

            &__text {
                font-size: 14px;
                color: #414141;
                display: block;
                overflow: hidden;
                box-sizing: border-box;
                padding: 35px;
            }
        }
    }

    .forms {
        margin: 30px 30px 0px;
        .info {
            &__text {
                margin-top: 20px;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 1.5;
                color: $btn-background;
                margin-bottom: 20px;
            }

            &__content {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                margin-top: 10px;
            }
        }

        .setting {
            &__text {
                font-size: 18px;
                font-weight: bold;
                color: #2a2a30;
                line-height: 21px;
                letter-spacing: 0.5px;
            }

            &__content {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                margin-top: 10px;
            }
        }
    }

    .general-error {
        letter-spacing: $help-text-spacing;
        font-size: $general-error-text-size;
        color: $input-color-help-text;
        text-align: center;
    }
}