@import "../../../assets/sass/var.scss";

.button {
    border-radius: 10px;
    border: none;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    color: $btn-text-color;
    letter-spacing: 0.25px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    padding: 16px 60px;

    &_size {
        &-small {
            padding: 8px 12px;
            font-size: 14px;
            line-height: 21px;
        }

        &-big {
            padding: 16px 154px;
            font-weight: bold;
            font-size: 16px;
        }
    }

    &_position {
        &-left {
            float: left;
        }

        &-center {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }

        &-right {
            float: right;
        }
    }

    &_color {
        &-gray {
            background-color: $btn-color-gray;
            color: $paragraph-text-background;
            cursor: pointer;
        }

        &-gray:hover {
            background-color: $day-color;
            color: white;
        }

        &-gray:disabled {
            opacity: 0.9;
        }

        &-gray2 {
            background-color: #EFF0F6;
            color: #737484;
            font-weight: bold;
            cursor: pointer;
        }

        &-gray2:hover {
            background-color: #2A2A30;
            color: white;
            font-weight: bold;
        }

        &-green {
            //background-color: $day-color;
            border: 2px solid $day-color;
            color: $day-color;
            background: transparent;
            cursor: pointer;
        }

        &-green:hover {
            background-color: $day-color;
            border: 2px solid $day-color;
            color: white;
        }

        &-black {
            background-color: $btn-background;
            color: white;
            cursor: pointer;
            border: 2px solid black;
        }

        &-disabled {
            background-color: #EFF0F6;
            color: $paragraph-text-background;
        }

        &-black:hover {
            background-color: $day-color;
            color: white;
            border: 2px solid $day-color;
        }

        &-transparent {
            background-color: transparent;
            border: 2px solid $btn-border;
            color: $btn-background;
            text-decoration: none;
            cursor: pointer;
        }

        &-transparent:hover {
            background-color: $btn-hover-blue;
            color: $btn-text-color;
            border: 2px solid $btn-hover-blue;
        }

        &-delete {
            color: $btn-background;
            background-color: transparent;
            border: 2px solid #fd510dc9;
            color: #fd510dc9;
            cursor: pointer;
        }

        &-delete:hover {
            background-color: #fd510d;
            color: white;
        }

        &-blue {
            background-color: $btn-hover-blue;
            border: 2px solid $btn-hover-blue;
            color: white;
            text-decoration: none;
            cursor: pointer;
        }

        &-blue2 {
            background-color: transparent;
            border: 2px solid $btn-hover-blue;
            color: $btn-hover-blue;
            //text-decoration: none;
            cursor: pointer;

        }

        &-blue2:hover {
            background-color: $btn-hover-blue;
            border: 2px solid $btn-hover-blue;
            color: white;
            //text-decoration: none;
            cursor: pointer;

        }

        &-green2 {
            border: 2px solid $day-color;
            color: $day-color;
            background: transparent;
            cursor: pointer;

        }

        &-delete2 {
            color: $btn-background;
            background-color: transparent;
            border: 2px solid #fd510dc9;
            color: #fd510dc9;
            cursor: pointer;

        }
    }
}