.dateTimePicker {
    .react-datepicker {
        border-radius: 13px !important;
        border: none !important;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;
        &__week {
            margin: 3px !important;

        }
        &__month-container {
            border-right: 1px solid #adadad !important;
            padding: 10px !important;
        }

        &-popper {
     position: absolute !important; 
     inset: 0px auto auto 0px !important; 
     transform: translate3d(875px, 938px, 0px) !important;
            
            inset: -33px auto auto 0px !important;
           // transform: translate3d(984px, 830px, 0px) !important;
       

        }

        &__triangle {
            display: none !important;
            left: -8px !important;
        }

        &__triangle::after {
            border-bottom-color: white !important;


        }

        &__triangle::before {
            border-bottom-color: white !important;


        }

        &__header {
            text-transform: capitalize;
            background-color: white !important;
            border: none !important;

            //margin-top:10px !important;
            &--time {
                margin-top: 45px !important;
                margin-bottom: 10px !important;
            }
        }

        &__current-month {
            &--hasYearDropdown {}
        }

        &__day {
            &--outside-month {
                color: gray !important;
            }

            &--weekend {
                color: red !important;
            }

            &-name {
                font-weight: bold;
                font-size: 14px;
            }

            &-names {
                margin-top: 10px;
            }

            &--today {
                border-radius: 7px !important;
                background-color: white !important;
                color: black !important;
            }

            &--selected {
                background-color: #49CD6E !important;
                color: white !important;
                font-weight: bold !important;
            }

            &--keyboard-selected {
                background-color: #49CD6E !important;
                color: white !important;
                font-weight: bold !important;

            }
        }

        &__navigation {
            margin-top: 10px !important;

            &-icon::before {
                border-width: 1.5px 1.5px 0 0 !important;
            }

        }

        &__time {
            &-container {
                border: none !important;
            }
            position: absolute !important;
            &-list {
                height: 187px !important;
                margin-left: 13px !important;

                //margin-bottom: -82px !important;
                &-item {
                    //margin-bottom: 9px;
                    margin: 7px;
                    //margin-top: 3.73px !important;
                    //height: 30px !important;
                    //margin-top: 4.5px;
                }
                &-item {
                    padding: 7px 10px !important;
                    width: 50px;
                    
                    

                    &--selected {
                        background-color: #49CD6E !important;
                        border-radius: 7px;
                        padding: 7px 10px !important;
                        width: 50px;

                    }
                
                   
                   
                }
                &-item:hover {
                   border-radius: 7px !important;
                   padding: 7px 10px !important;
                   width: 50px;
                   
                   
                }
            }

        }


    }

}