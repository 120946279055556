@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.details {
  padding-bottom: 40px;
  grid-area: content;

  &__title {
    margin-left: 30px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
  }

  &__detail {
    margin: 10px;
    margin-left: 30px;
  }

}