@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.events {
  padding-bottom: 40px;
  grid-area: content;
  &__title {
    margin-left: 30px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
  }

  &__add {
    font-weight: bold;
    font-size: 16px;
    float: right;
    margin-top: -65px;
    margin-right: 20px;
  }

  &__sort {
    float: right;
    padding: 0 10px;
    margin: -15px 20px 10px 0;
    .sort-title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      .title-name {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
      }

      .title-value {
        font-weight: 600;
        background-size: 12px 12px;
        .arrow {
          width: 15px;
          height: 15px;
          margin-left: 10px;
        }
      }
    }

    .sort-list {
      display: none;
      position: absolute;
      right: 20px;
      padding: 24px;
      background: #ffffff;
      box-shadow: 0 0 10px rgb(0 0 0 / 10%);
      border-radius: 12px;
      list-style: none;
    }

    .sort-list-open {
      position: absolute;
      right: 20px;
      padding: 24px;
      background: #ffffff;
      box-shadow: 0 0 10px rgb(0 0 0 / 10%);
      border-radius: 12px;
      list-style: none;
      .list-item {
        display: block;
        color: #737484;
        padding: 5px 0;
      }

      .list-item:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__table {
    table-layout: revert;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    .thead {
      .th {
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $btn-background;
        border: solid $background-border-table;
        border-width: 1px 0;
      }
    }

    .tbody {
      .tr {
        background: $background-even;
        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }

        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
        }

        .td-color{
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
          border-left: 2px solid black;
          width: calc(100% - 10px);
          padding: 10px;
          box-sizing: border-box;
          color: #2A2A30 ;
          z-index: 1;
          list-style: none;
        }

        .td.td-button-group {
          margin: 0 auto;
          width: 60%;
          display: flex;
          flex-direction: row;
          justify-content: end;
          margin-right: 10px;
          align-items: center;
        }

        .date-event {
          display: flex;
          flex-direction: column; 
        }
         .time {
           white-space: nowrap;
        } 

        .button {
          margin-left: 15px;
        }
      }
      
      .tr:hover {
        background: rgba(219, 217, 217, 0.459);
      }
    }
  }
}