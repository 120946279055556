@import "src/assets/sass/var";

.delete-window {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.199);
  display: flex;
  align-items: center;
  justify-content: center;
  &__alert {
    padding: 20px ;
    border-radius: 5px;
    background: $main-background;
    width: 512px;
    height: 220px; 
    transition: .5s all;
    .title {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding: 0.8em 1em 0;
      color: inherit;
      font-size: 1.875em;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
    }

    .btn-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .button {
      margin: 5px;
    }
  }
}





/*
@import "src/assets/sass/var";

.delete-window {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.199);


  &__alert {
    width: 512px;
    height: 220px;
    position: absolute;
    left: 38%;
    top: 38%;

    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    max-width: 100%;
    border: none;
    border-radius: 5px;
    background: $main-background;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
    padding: 20px;

    .title {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding: 0.8em 1em 0;
      color: inherit;
      font-size: 1.875em;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
    }

    .btn-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
*/
