.timePicker {
    .react-datepicker {
        &-popper {
            inset: auto !important;
            transform: translate(0) !important;
            margin-top: 5px;
        }
        &__time-box {
            display: flex !important;
            justify-content: center !important;
        }
        &__time-list{
            &-item {
                margin-left: 17px !important;
                width: 50px !important;

                &--selected {
                    background-color: #49CD6E !important;
                    border-radius: 8px !important;
                    width: 50px;
                }
            }
            &-item:hover {
                border-radius: 8px !important;

            }
        }

        &--time {

            &-only {
                border: none !important;
                border-radius: 13px !important;
                box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;
                //left: 418px;
                top: -35px
            }
        }

        &__triangle {
            display: none;
        }

        &__header {
            border: none !important;
            background-color: white !important;

            &--time {
                &-only {}
            }
        }
    }

}