@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

.schedule {
  grid-area: content;
  &__title-wrapper {
    display: flex;
    .title {
      margin-left: 30px;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }
  }

  &__calendar {
    display: block;
    margin-top: -70px;
    .header {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 10px 0;
      margin-bottom: 40px;
      .nav {
        display: flex;
        float: right;
        .nav-current {
          float: left;
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.3px;
          padding: 5px 30px;
          border-right: 2px solid #D6D8E7;
        }

        .calendar-img {
          margin-right: 8px;
        }

        .nav-week {
          float: left;
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.3px;
          margin: 5px 30px;
        }

        .nav-week:hover {
          cursor: pointer;
        }

        .nav-prev {
          margin-right: 15px;
          padding: 5px 0;
          float: left;
        }

        .nav-prev:hover {
          cursor: pointer;
        }

        .nav-next {
          margin-right: 40px;
          padding: 5px 0;
          float: left;
        }

        .nav-next:hover {
          cursor: pointer;
        }
      }
    }

    .timeLine {
      display: block;
      position: absolute;
      padding-top: 60px;
      background: $background-even;
      font-size: 16px;
      font-family: Poppins, sans-serif;
      color: $paragraph-text-background;
      width: calc(100% - 290px);
      .time-title {
        font-family: 'PoppinsRegular';
        position: absolute;
        top: 0;
        width: 100px;
        text-align: center;
        height: 60px;
        margin-bottom: 20px;
        padding-top: 5px;
        background: #F6F6F8;
        border-top: 1px solid #E7E6E6;
        border-bottom: 1px solid #E7E6E6;
        font-weight: 600;
        color: #2A2A30;
        font-size: 16px;
        line-height: 24px;
      }

      .line {
        padding: 10px 0 0 30px;
        display: block;
        width: 100%;
        height: 180px;
        box-sizing: border-box;
        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }
      }
    }
    // date now
    .dayNow {
      border-left: 1px solid $background-border-table;
      border-top: 0;
      position: relative;
      float: left;
      flex-grow: 1;
      color: #000000;
      background: $text-todayDate-color;
      .dayDateNow {
        text-align: center;
        height: 60px;
        margin-bottom: 20px;
        padding-top: 5px;
        border-top: 1px solid #E7E6E6;
        border-bottom: 1px solid #E7E6E6;
        display: flex;
        flex-direction: column;
      }

      .dayDate {
        text-align: center;
        height: 60px;
        margin-bottom: 20px;
        padding-top: 5px;
        background: #F6F6F8;
        border-top: 1px solid #E7E6E6;
        border-bottom: 1px solid #E7E6E6;
        display: flex;
        flex-direction: column;
        &_weekDay {
          font-weight: 600;
          display: block;
          color: #2A2A30;
          font-size: 16px;
          line-height: 24px;
        }

        &_date {
          font-size: 14px;
          line-height: 21px;
          color: $paragraph-text-background;
        }

        &_free-day-block {
          margin-top: -20px;
          &_free-day {
            background-color: darkgray;
            opacity: 0;
            padding: 10px 0 0 30px;
            display: block;
            width: 100%;
            height: 180px;
            box-sizing: border-box;
            &:nth-of-type(even) {
              background-color: burlywood;
            }

            .free-time-block {
              margin-top: -10px;
              margin-left: -20px;
              .time-elem {
                height: 15px;
              }
            }
          }

          &_free-day:hover {
            cursor: pointer;
          }
        }
      }

      .day-event {
        display: inherit;
        border-left: 2px solid black;
        position: absolute;
        width: calc(100% - 10px);
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        font-size: 12px;
        z-index: 2;
        background: white;
        .reason {
          font-weight: bold;
          display: block;
        }

        .start {
          display: block;
          font-weight: normal;
          .waiting-room {
            margin-left: 10px;
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid #49CD6E;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
          .photo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .img-ok {
          width: 22px;
          height: 22px;
          border: 2px solid #49CD6E;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .img-Close {
          width: 22px;
          height: 22px;
          border: 2px solid #fd510dc9;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }
      }

      .day-event:hover {
        cursor: pointer;
        .edd-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .day-several-events {
        display: none;
        border-left: 2px solid black;
        position: absolute;
        width: calc(100% - 10px);
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        font-size: 12px;
        z-index: 1;
        list-style: none;
        &:first-child {
          display: inherit;
        }

        .reason {
          font-weight: bold;
          display: block;
        }

        .start {
          display: block;
          font-weight: normal;
          .waiting-room {
            margin-left: 10px;
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid #49CD6E;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
          display: none;
          .photo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: none;
          }
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .img-ok {
          width: 22px;
          height: 22px;
          border: 2px solid #49CD6E;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .img-Close {
          width: 22px;
          height: 22px;
          border: 2px solid #fd510dc9;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }
      }

      .day-several-events:hover {
        cursor: pointer;
        .edd-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .amount-events {
        position: absolute;
        top: -10%;
        left: 85%;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .amount-content {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.25px;
          color: #49CD6E;
        }
      }

      .edd-button {
        position: absolute;
        top: 34%;
        left: 85%;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #49CD6E;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        display: none;
        &_title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0.25px;
          color: #FFFFFF;
        }
      }
    }

    //holiday
    .dayHoliday {
      border-left: 1px solid $background-border-table;
      border-top: 0;
      position: relative;
      float: left;
      flex-grow: 1;
      color: #000000;
      background: $text-holidayDate-color;
      .dayDateHoliday {
        text-align: center;
        height: 60px;
        margin-bottom: 20px;
        padding-top: 5px;
        border-top: 1px solid #E7E6E6;
        border-bottom: 1px solid #E7E6E6;
        display: flex;
        flex-direction: column;
      }

      .dayDate {
        text-align: center;
        height: 60px;
        margin-bottom: 20px;
        padding-top: 5px;
        background: #F6F6F8;
        border-top: 1px solid #E7E6E6;
        border-bottom: 1px solid #E7E6E6;
        display: flex;
        flex-direction: column;
        &_weekDay {
          font-weight: 600;
          display: block;
          color: #2A2A30;
          font-size: 16px;
          line-height: 24px;
        }

        &_date {
          font-size: 14px;
          line-height: 21px;
          color: $paragraph-text-background;
        }

        &_free-day-block {
          margin-top: -20px;
          &_free-day {
            background-color: darkgray;
            opacity: 0;
            padding: 10px 0 0 30px;
            display: block;
            width: 100%;
            height: 180px;
            box-sizing: border-box;
            &:nth-of-type(even) {
              background-color: burlywood;
            }

            .free-time-block {
              margin-top: -10px;
              margin-left: -20px;
              .time-elem {
                height: 15px;
              }
            }
          }

          &_free-day:hover {
            cursor: pointer;
          }
        }
      }

      .day-event {
        display: inherit;
        border-left: 2px solid black;
        position: absolute;
        width: calc(100% - 10px);
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        font-size: 12px;
        z-index: 1;
        .reason {
          font-weight: bold;
          display: block;
        }

        .start {
          display: block;
          font-weight: normal;
          .waiting-room {
            margin-left: 10px;
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid #49CD6E;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
          .photo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .img-ok {
          width: 22px;
          height: 22px;
          border: 2px solid #49CD6E;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .img-Close {
          width: 22px;
          height: 22px;
          border: 2px solid #fd510dc9;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }
      }

      .day-event:hover {
        cursor: pointer;
        .edd-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .day-several-events {
        display: none;
        border-left: 2px solid black;
        position: absolute;
        width: calc(100% - 10px);
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        font-size: 12px;
        z-index: 1;
        list-style: none;
        &:first-child {
          display: inherit;
        }

        .reason {
          font-weight: bold;
          display: block;
        }

        .start {
          display: block;
          font-weight: normal;
          .waiting-room {
            margin-left: 10px;
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid #49CD6E;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
          display: none;
          .photo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: none;
          }
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .img-ok {
          width: 22px;
          height: 22px;
          border: 2px solid #49CD6E;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .img-Close {
          width: 22px;
          height: 22px;
          border: 2px solid #fd510dc9;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }
      }

      .day-several-events:hover {
        cursor: pointer;
        .edd-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .amount-events {
        position: absolute;
        top: -10%;
        left: 85%;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .amount-content {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.25px;
          color: #49CD6E;
        }
      }

      .edd-button {
        position: absolute;
        top: 34%;
        left: 85%;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #49CD6E;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        display: none;
        &_title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0.25px;
          color: #FFFFFF;
        }
      }
    }

    .days {
      display: flex;
      overflow: hidden;
      width: calc(100% - 100px);
      margin-left: 100px;
      .day {
        border-left: 1px solid $background-border-table;
        border-top: 0;
        position: relative;
        float: left;
        flex-grow: 1;
        color: #000000;
        .dayDate {
          text-align: center;
          height: 60px;
          margin-bottom: 20px;
          padding-top: 5px;
          background: #F6F6F8;
          border-top: 1px solid #E7E6E6;
          border-bottom: 1px solid #E7E6E6;
          display: flex;
          flex-direction: column;
          &_weekDay {
            font-weight: 600;
            display: block;
            color: #2A2A30;
            font-size: 16px;
            line-height: 24px;
          }

          &_date {
            font-size: 14px;
            line-height: 21px;
            color: $paragraph-text-background;
          }

          &_free-day-block {
            margin-top: -20px;
            &_free-day {
              background-color: darkgray;
              opacity: 0;
              padding: 10px 0 0 30px;
              display: block;
              width: 100%;
              height: 180px;
              box-sizing: border-box;
              &:nth-of-type(even) {
                background-color: burlywood;
              }

              .free-time-block {
                margin-top: -10px;
                margin-left: -20px;
                .time-elem {
                  height: 15px;
                }
              }
            }

            &_free-day:hover {
              cursor: pointer;
            }
          }
        }

        .day-event {
          display: inherit;
          border-left: 2px solid black;
          position: absolute;
          width: calc(100% - 10px);
          padding: 10px;
          border-radius: 0 12px 12px 0;
          box-sizing: border-box;
          color: #2A2A30;
          font-size: 12px;
          z-index: 1;
          .reason {
            font-weight: bold;
            display: block;
          }

          .start {
            display: block;
            font-weight: normal;
            .waiting-room {
              margin-left: 10px;
            }

            .waiting-room:hover {
              cursor: pointer;
              border-bottom: 2px solid #49CD6E;
            }
          }

          .client {
            box-sizing: border-box;
            overflow: hidden;
          }

          .doctor {
            box-sizing: border-box;
            overflow: hidden;
          }

          .doctor-photo {
            margin-left: 85%;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
            .photo {
              width: 28px;
              height: 28px;
              border-radius: 50%;
            }
          }

          .waiting-room {
            margin-left: 85%;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
          }

          .img-ok {
            width: 22px;
            height: 22px;
            border: 2px solid #49CD6E;
            border-radius: 15%;
            padding: 3px;
            margin-left: 10px;
          }

          .img-Close {
            width: 22px;
            height: 22px;
            border: 2px solid #fd510dc9;
            border-radius: 15%;
            padding: 3px;
            margin-left: 10px;
          }

          .waiting-room {
            margin-left: 85%;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
            .img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
            }
          }
        }

        .day-event:hover {
          cursor: pointer;
          .edd-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        .day-several-events {
          display: none;
          border-left: 2px solid black;
          position: absolute;
          width: calc(100% - 10px);
          padding: 10px;
          border-radius: 0 12px 12px 0;
          box-sizing: border-box;
          color: #2A2A30;
          font-size: 12px;
          z-index: 1;
          list-style: none;
          &:first-child {
            display: inherit;
          }

          .reason {
            font-weight: bold;
            display: block;
          }

          .start {
            display: block;
            font-weight: normal;
            .waiting-room {
              margin-left: 10px;
            }

            .waiting-room:hover {
              cursor: pointer;
              border-bottom: 2px solid #49CD6E;
            }
          }

          .client {
            box-sizing: border-box;
            overflow: hidden;
          }

          .doctor {
            box-sizing: border-box;
            overflow: hidden;
          }

          .doctor-photo {
            margin-left: 85%;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
            display: none;
            .photo {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              display: none;
            }
          }

          .waiting-room {
            margin-left: 85%;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
          }

          .img-ok {
            width: 22px;
            height: 22px;
            border: 2px solid #49CD6E;
            border-radius: 15%;
            padding: 3px;
            margin-left: 10px;
          }

          .img-Close {
            width: 22px;
            height: 22px;
            border: 2px solid #fd510dc9;
            border-radius: 15%;
            padding: 3px;
            margin-left: 10px;
          }
        }

        .day-several-events:hover {
          cursor: pointer;
          .edd-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        .amount-events {
          position: absolute;
          top: -10%;
          left: 85%;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: #FFFFFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .amount-content {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.25px;
            color: #49CD6E;
          }
        }

        .edd-button {
          position: absolute;
          top: 34%;
          left: 85%;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: #49CD6E;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
          display: none;
          &_title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  &__wrapper-calendar-general {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.199);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .react-calendar {
      border-radius: 5px !important;
      padding: 20px !important;
      background: $main-background !important;
      height: 520px !important;
    }
  }
}


