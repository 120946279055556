@import "../../assets/sass/var";

* {
  box-sizing: border-box;
}

.field {
  margin-bottom: 40px;
  position: relative;

  &_focus {
    .field {
      &__label {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .field {
      &__label {
        transition: 0s;
      }
    }
  }

  &_blur {
    .field {
      &__label {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .field {
      &__label {}
    }
  }

  &_focus {
    .field {
      &__labels {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .field {
      &__labels {
        transition: 0s;
      }
    }
  }

  &_blur {
    .field {
      &__labels {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .field {
      &__labels {}
    }
  }

  &__input {
    height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $input-text-font-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0px;
  }

 
  &__label {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__imgNone {
    display: none !important;

  }

  &__img {
    position: absolute;
    float: right;
    right: 10px;
    top: 10px;
    padding: 10px;

  }

  .react-calendar {
    &__century-view {
      &__decades {
        width: 291px !important;
        justify-content: center !important;

        &__decade {
          overflow: visible !important;
        }
      }
    }

    &__month-view {
      &__days {
        &__day {}
      }
    }

    &__year-view {
      &__months {
        width: 307px !important;

        &__month {
          overflow: visible !important;
        }
      }
    }

    &__decade-view {
      &__years {
        justify-content: center;

        &__year {
          overflow: visible !important;
        }
      }
    }

    &__tile {
      &--hasActive {
        background: #49CD6E !important;
        color: white !important;
        padding: 5px !important;
      }
    }

    margin-top: 10px;
    right: 0;
    background-color: white;
    padding: 15px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 13px;
    position: absolute;
    z-index: 100 !important;
    width: 320px !important;

    &__navigation {
      height: 20px !important;

      &__label:hover {
        background-color: transparent !important;
      }

      &__label:focus {
        background-color: transparent !important;
      }

      &__label {
        background-color: transparent !important;
      }

      &__arrow {
        background-color: transparent !important;
      }

      &__arrow:focus {
        background-color: transparent !important;
      }

      &__arrow:hover {
        background-color: transparent !important;
      }

      &__prev-buttom {
        background-color: transparent !important;

      }

      &__prev-buttom:hover {
        background-color: transparent !important;

      }

      &__prev-buttom:focus {
        background-color: transparent !important;

      }
    }

    abbr {
      font-size: 14px !important;
    }

    button {
      font-size: 14px !important;
      margin-bottom: 0px !important;
      padding: 5px !important;
      padding-left: 5px;
      border-radius: 0px;
    }

    &__month-view {
      &__weekdays__weekday {
        margin: 0px !important;
      }
    }
  }

  &__labels {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__select {
    min-height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $input-text-font-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0px;
  }

  &__option {
    margin-top: 10px;
  }

  &__clear :hover {
    opacity: 0.7;
  }

  &__clear {
    color: $btn-clear;
    position: absolute;
    top: 20px;
    right: 29px;
    z-index: 1;
  }

  &_datePicker {
    .field {
      &__label {
        position: absolute;
        top: 0px;
        left: 18px;
        margin: 8px;
        letter-spacing: 0.4px;
        transition: 0s;
        font-size: 14px;
        color: $paragraph-text-background;
      }

      &__input::placeholder {
        color: black;

      }

    }


  }

  &_order {
    .field {
      &__input {
        margin-bottom: 7px;
        position: relative;
        height: 28px;
        width: 100%;
        background-color: transparent;
        padding: 1px 1px;
        color: $btn-background;
        border: none;
        font-weight: normal;
        cursor: text;
        border-radius: 0px;
        text-align: center;
        font-size: 15px;
        box-sizing: border-box;
        outline: none;
      }

      &__input:hover {
        border: 1.5px solid $btn-background;
        //height: 64px;
        background-color: $btn-text-color;
      }

      &__input:focus {
        border: 1.5px solid #2694da;
        background-color: $btn-text-color;
      }

      &__label {
        font-weight: lighter;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
        position: relative;
        top: -30px;
        left: 0px;
      }

      &__labels {
        opacity: 0;
        font-weight: lighter;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
        position: relative;
        top: -32px;
        left: 0px;
      }
    }
  }

  &_active {
    .field {
      &__input:focus {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__help-text {
        font-size: 14px;
        letter-spacing: $help-text-spacing;
        color: #737484;
      }
    }
  }

  &_date {
    .field {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_datetime {
    .field {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_time {
    .react-calendar {
      display: none;
    }

    .field {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_typing {
    .field {
      &__input:focus {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }
    }
  }

  &_disabled {
    .field {
      &__input {
        background-color: $main-background;
      }
    }
  }

  &_caption {
    .field {
      &__input {
        margin-bottom: 9px;
      }

      &__help-text {
        font-size: $help-text-size;
        letter-spacing: $help-text-spacing;
        color: $paragraph-text-background;
      }

      &__clear {
        margin-top: -75px;
      }
    }
  }

  /*  &_search {
    .field {
      &__error {
        .field {
          &__input {
          }
        }
      }

      &__input {
        background-image: url("../../assets/img/search.svg");
        background-repeat: no-repeat;
        background-position: 23px;
        padding: 22px 62px;
        padding-bottom: 0px;
      }

      &__label {
        left: 56px;
      }

      &__clear {
        margin-left: 283px;
      }
    }
  }  */

  &_search {
    .field {
      &__input {
        //margin-top: 28px;
        padding: 24px;
        //padding-top: -22px;
        padding-bottom: 0px;
      }

      &__label {
        left: 18px;
        margin-top: 1px;
      }

      &__labels {
        left: 18px;
        margin-top: 1px;
      }
    }
  }

  &_user {
    .field {
      &__input {
        background-image: url("../../assets/img/profile.svg");
        background-repeat: no-repeat;
        background-position: 20px;
        background-color: transparent;
        padding: 25px 57px;
      }

      &__label {
        left: 50px;
      }
    }
  }

  &_success {
    .field {
      &__input {
        background-color: #f2fffb;
        border: 2px solid #00ba88;
      }

      &__help-text {
        font-size: $help-text-size;
        letter-spacing: $help-text-spacing;
        color: #00966d;
      }
    }
  }

  &_error {
    .field {
      &__input {
        background-color: $input-color-error;
        border: 2px solid $input-border-error;
      }

      &__label {
        color: $input-color-help-text;
      }

      &__clear {
        margin-top: -69px;
        color: $input-border-error;
      }

      &__help-text {
        letter-spacing: $help-text-spacing;
        font-size: $help-text-size;
        color: $input-color-help-text;
        padding-top: -20px;
      }
    }
  }
}

.order {
  margin-bottom: -27px;

  .react-calendar {
    display: none;
  }

  &_focus {
    .field {
      &__labels {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .field {
      &__labels {
        display: none;
        transition: 0s;
      }
    }
  }

  &_order {
    .order {
      &__input {
        cursor: text;
      }
    }
  }
}