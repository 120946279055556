.form {
  width: 800px;
  margin-left: 350px;
  display: flex;
  flex-direction: column;
}

.group {
  border: 1px solid #ccc;
  padding: 10px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
}