@import "../../../assets/sass/var";

* {
    box-sizing: border-box;
}

.date {
    background: $main-background;
    height: 100%;
    width: 100%;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    &__header {
        &-info {
            position: relative;
            margin-top: 72px;
            margin-bottom: 25px;
            padding-left: 120px;
        }
        
        &_info-img1 {
            position: absolute;
            float: left;
        }

        &_info-img2 {
            position: absolute;
            margin-left: 75px;
        }

        &_info-img3 {
            margin-left: 75px;
            margin-top: 7px;
        }
    }

    &__line {
        width: 1198px;
        margin-left: 120px;
        border: 1px solid $background-success;
    }

    &__title {
        margin-left: 620px;
        margin-top: 50px;
    }

    &__content {
        background-color: $main-background;
        margin-top: 55px;
        margin-left: 325px;
        width: 790px;
        height: 520px;
        display: flex;
    }

    &__information {
        width: 790px;
        height: 570px;
        background-color: $btn-text-color;
        border-radius: 20px;
        &-data {
            padding-left: 201px;
            padding-top: 60px;
            width: 389px;
            height: 70px;
            margin: 60px 200px 30px 201px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 20px;
            background: $btn-text-color;
            border: 2px solid $btn-color-gray;
            box-sizing: border-box;
            border-radius: 12px;
        }

        &_data-img {
            mix-blend-mode: multiply;
        }

        &_data-name {
            position: static;
            width: 306px;
            height: 30px;
            left: 56px;
            top: 20px;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.25px;
            color: #2a2a30;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0 12px;
        }

        &_data-day {
            font-weight: bold;
            color: $day-color;
        }

        &-btn {
            margin-left: 307px;
            width: 176px;
            height: 56px;
            background-color: $btn-background;
            margin-top: 40px;
            border-radius: 10px;
            border: none;
            color: $btn-text-color;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.25px;
        }

        &-text {
            margin: 40px 60px 60px 60px;
            background-color: $main-background;
            border-radius: 10px;
            padding: 20px 20px 30px 20px;
        }

        &_text-name {
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.25px;
            color: $btn-background;
        }
        
        &_text-paragraph {
            font-size: 1vw;
            position: relative;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
            color: $paragraph-text-background;
        }
    }
}
