@import "src/assets/sass/var";

.admin__left-nav {
  display: block;
  position: fixed;
  width: 290px;
  margin-top: 120px;

  //Grid
  //display: block;
  //width: 290px;
  //grid-area: navbar;
  //overflow: scroll;
  //height: 100vh;
  //margin-top: 67px;
  //padding-bottom: 100px;

  .list {
    list-style: none;
    padding: 0 30px;
    margin: 0;
    .item a {
      list-style: none;
      line-height: 1;
      padding: 0 0 20px 0;
      margin: 0;
      display: block;
      font-size: 16px;
      color: $paragraph-text-background;
      letter-spacing: 0.25px;
      text-decoration: none;
    }

    .item a:hover {
      text-decoration: underline;
    }
    
    .item a:active {
      font-weight: bold;
    }
  }
}